import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import PaginationButton from "../components/crumbs/paginationButton"
import { useStaticQuery, graphql } from "gatsby"
import Boxes from "../components/crumbs/boxes"
import PageSeo from "../components/crumbs/pageSeo"
import LayoutList from "../components/layouts/layoutList"

export default function Home() {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 25, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Gazdaság"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }           
              excerpt
          }
        }
      }
    }
  `)

  const refreshUrl = "/gazdasag"
  const next = `/kereses${refreshUrl}/1`
  const prev = null

  const boxNo = 1
  const articles = allStrapiArticle.edges.map(item => <Boxes box={boxNo} article={item.node}/>)

  return (
    <> 
      <PageSeo
        title="Minden Percben | Gazdaság"
        description="Minden amiről tudnia érdemes a gazdaságban. Mert minden perc érdekes!"
        keywords="gazdaság, tőzsdei hírek, pénzpiacok, befektetés, hitel"
        canonical="https://www.mindenpercben.hu/gazdasag"
        canonicalMobile = "https://m.mindenpercben.hu/gazdasag"        
      />
      <LayoutSite 
        refreshUrl={refreshUrl}
        content={
          <>
            <LayoutList articles={articles} category="gazdasag"/>
            <PaginationButton next={next} prev={prev}/>
          </>
        }        
      />
    </>
  )
}